@import url("../fonts/main-font.css");
@import url("../main/reset.css");
@import url("../main/general.css");

.media-html{
    font-size: 50px;
    background-color: white;
    opacity:0;
}

.custom-html{
    pointer-events: none;
}

.smartq-img-fader{
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    right: 0;
    left:0;
    bottom:0;
}
.smartq-img-fader .videoPlayer{
    width: 100%;
}

.smartq-img-fader img {
    max-width: 100%;
    max-height: 100%;

    opacity: 0;
    visibility: hidden;
}

.smartq-img-fader > div:not(.media-html) > div[preload=true],
.smartq-img-fader > div:not(.media-html) {
    /*height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;*/

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    display: -ms-flexbox;
    display: flex;

    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-align: center;
    align-items: center;
}


.smartq-img-fader video {
    width: 100%;
}
